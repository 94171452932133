import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  footer:{
    boxShadow:'0px -4px 10px -4px #422339',
    height: '56px',
    width: '100%',
    position:'absolute',
    background: '#f5f5f5',
    bottom:0,
  },
}

type Props = {
  classes: any,
};

class Footer extends Component<Props> {

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div
          className={classes.footer}
        >
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Footer));
