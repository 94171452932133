import React, { Component } from 'react';
import { withRouter } from 'react-router'
import {
  AppBar,
  Toolbar,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import './../App.css';
import BuildIcon from '@material-ui/icons/Build';
import Footer from './footer';

const styles = theme => ({
  root: {
    marginTop: '30px',
    margin: '10px',
    flexGrow: 1,
    display: 'block',
    textAlign: 'center',
  },
  stepIcon: {
    color: theme.palette.primary.dark,
    marginRight: theme.spacing.unit * 2,
    fontSize: 30,
  },
});

type Props = {
  classes: any,
};

class MainteView extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <AppBar position="static" color="default">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              対応状況
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          className={classes.root}
        >
          <BuildIcon className={classes.stepIcon} />
          <Typography variant="headline" color="inherit">
            只今メンテナンス中の為、ご利用になれません。
          </Typography>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(MainteView));

