import React from 'react';
import { BrowserRouter, Route, withRouter } from 'react-router-dom'
import StatusView from './components/statusView';
import MainteView from './components/mainteView';
import ErrorView from './components/errorView';
import ExpirationView from './components/expirationView';

const Routes = withRouter(() => {
  return (
    <div>
      <Route exact path='/' component={MainPage} />
      <Route path='/mainte' component={MaintePage} />
      <Route path='/expiration' component={expirationPage}/>
      <Route path='/error' component={ErrorPage} />
    </div>
  )
})

const App = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);

const MainPage = () => (
  <div>
    <StatusView />
  </div>
);

const MaintePage = () => (
  <div>
    <MainteView />
  </div>
);

const expirationPage = () => (
  <div>
    <ExpirationView />
  </div>
);

const ErrorPage = () => (
  <div>
    <ErrorView />
  </div>
)

export default App;
